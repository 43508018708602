import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./LandingPage.css";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { signInWithCredential, GoogleAuthProvider } from "firebase/auth"; // Firebase Authentication
import { auth } from "../firebase"; // Import Firebase Auth
import { useUser } from "../context/UserContext"; // Access user context

const LandingPage = () => {
  const { user, setUser } = useUser(); // Access global user state
  const navigate = useNavigate(); // Navigate to other routes

  const handleLoginSuccess = async (credentialResponse) => {
    const credential = GoogleAuthProvider.credential(
      credentialResponse.credential
    );

    try {
      // Sign in with Firebase Authentication
      const result = await signInWithCredential(auth, credential);
      const firebaseUser = result.user;

      // Map Firebase user data
      const mappedUser = {
        id: firebaseUser.uid,
        name: firebaseUser.displayName,
        email: firebaseUser.email,
        picture: firebaseUser.photoURL,
      };

      // Save user to context
      setUser(mappedUser);

      // Save user to backend (Firestore)
      const response = await fetch(
        "https://api-e2vtt5kn2q-uc.a.run.app/api/users/save",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(mappedUser),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("User saved successfully:", data.message);
        navigate("/recipes"); // Redirect to My Recipes
      } else {
        const errorData = await response.json();
        console.error("Error saving user:", errorData.error);
      }
    } catch (error) {
      console.error("Error during login:", error.message);
    }
  };

  const handleLoginError = () => {
    console.error("Google Sign-In Failed");
  };

  return (
    <GoogleOAuthProvider clientId="778406526027-sckpg5gkempcbomp1ganchj2f17m6ecn.apps.googleusercontent.com">
      <div className="landing-page full-screen">
        <div className="background-container">
          <img
            src="/hex-pattern.png"
            alt="landing-background"
            className="landing-background-image"
          />
        </div>
        <div className="logo-container">
          <img
            src="/apple-touch-icon.png"
            alt="Flaveo Logo"
            className="logo-image"
          />
        </div>
        <div className="landing-content">
          {user ? (
            <>
              <h1>Welcome, {user.name}!</h1>
              <img src={user.picture} alt={user.name} className="user-avatar" />
              <p>{user.email}</p>
              <Link to="/my-recipes">Go to My Recipes</Link>
            </>
          ) : (
            <>
              <h1>
                Welcome to <br />
                <span className="logo">FLAVEO</span>
              </h1>
              <p>Your recipe collector</p>
              <GoogleLogin
                onSuccess={handleLoginSuccess}
                onError={handleLoginError}
              />
            </>
          )}
        </div>
        <div className="landing-page-footer links">
          {!user && (
            <>
              <Link to="/privacy-policy">privacy policy</Link>
              <Link to="/terms-of-service">terms of service</Link>
            </>
          )}
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default LandingPage;
