import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import "./MyRecipes.css"; // Include updated CSS for styling
import placeholderImage from "./placeholder-d.png"; // Use placeholder image for missing recipes
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../context/UserContext"; // Import user context

const MyRecipes = () => {
  const [recipes, setRecipes] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0); // Track current scroll position
  const [loading, setLoading] = useState(true); // Track loading state
  const navigate = useNavigate();
  const location = useLocation();
  const scrollContainerRef = useRef(null); // Ref for the scrollable container
  const { user } = useUser(); // Access logged-in user

  // Redirect to login if no user
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  // Restore scroll position when the component mounts
  useLayoutEffect(() => {
    if (
      !loading &&
      scrollContainerRef.current &&
      location.state?.scrollPosition !== undefined
    ) {
      console.log("Restoring Scroll Position:", location.state.scrollPosition);

      // Ensure content is rendered before restoring scroll
      requestAnimationFrame(() => {
        scrollContainerRef.current.scrollTo(0, location.state.scrollPosition);
      });
    }
  }, [location.state, loading]);

  // Dynamically track scroll position
  const handleScroll = () => {
    if (scrollContainerRef.current) {
      setScrollPosition(scrollContainerRef.current.scrollTop);
    }
  };

  // Capture the current scroll position and navigate to FullRecipeView
  const handleCardClick = (id) => {
    console.log("Captured Scroll Position:", scrollPosition); // Debug
    navigate(`/recipes/${id}`, { state: { scrollPosition } });
  };

  // Attach and detach scroll listener
  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // Fetch recipes from backend for the logged-in user
  useEffect(() => {
    const fetchRecipes = async () => {
      if (!user) {
        console.error("No user logged in. Cannot fetch recipes.");
        setRecipes([]);
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `https://api-e2vtt5kn2q-uc.a.run.app/api/recipes?userId=${user.id}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new Error("Response is not JSON");
        }

        const data = await response.json();
        setRecipes(data);
      } catch (error) {
        console.error("Failed to fetch recipes:", error);

        setRecipes([]); // Ensure recipes is empty if fetch fails
      } finally {
        setLoading(false); // Set loading to false after fetching recipes
      }
    };

    fetchRecipes();
  }, [user]);

  if (loading) {
    return <div>Loading your recipes...</div>;
  }

  return (
    <div className="favorites-page">
      <h1 className="page-title">My Recipes</h1>
      <div
        className="recipe-grid"
        ref={scrollContainerRef}
        style={{ overflowY: "auto", maxHeight: "80vh" }} // Ensure it's scrollable
      >
        {recipes.length > 0 ? (
          recipes.map((recipe) => (
            <div
              key={recipe.id}
              className="recipe-card"
              onClick={() => handleCardClick(recipe.id)}
              style={{ cursor: "pointer" }}
            >
              <div className="recipe-image">
                <img
                  src={recipe.image || placeholderImage}
                  alt={recipe.title || "Recipe"}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
              <h2 className="recipe-title">{recipe.title}</h2>
            </div>
          ))
        ) : (
          <div className="empty-state">
            <h2>No recipes yet</h2>
            <button className="cta-button" onClick={() => navigate("/import")}>
              Add Your First Recipe
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyRecipes;
