import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CustomButton from "./CustomButton";
import {
  TextField,
  Typography,
  CircularProgress,
  Box,
  Container,
  Button,
} from "@mui/material";
import { useUser } from "../context/UserContext"; // Import user context to access logged-in user

const RecipeImport = () => {
  const [url, setUrl] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [recipe, setRecipe] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useUser(); // Access logged-in user
  const navigate = useNavigate();

  const resetState = () => {
    setError("");
    setLoading(true);
    setRecipe(null);
    setImageFile(null);
  };

  const handleImportByUrl = async () => {
    if (!url.trim()) {
      setError("Please enter a valid recipe URL.");
      return;
    }

    if (!user) {
      setError("You must be logged in to import a recipe.");
      return;
    }

    resetState();

    try {
      const response = await axios.post(
        "https://api-e2vtt5kn2q-uc.a.run.app/api/recipes",
        {
          url,
          userId: user.id, // Include userId in the payload
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setRecipe(response.data);
    } catch (err) {
      console.error("API Error:", err);
      setError(
        err.response?.data?.error || "Failed to fetch recipe. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleImportByImage = async () => {
    if (!imageFile) {
      setError("Please upload an image file.");
      return;
    }

    if (!user) {
      setError("You must be logged in to import a recipe.");
      return;
    }

    setError("");
    setLoading(true);
    setRecipe(null);

    const formData = new FormData();
    formData.append("file", imageFile);
    formData.append("userId", user.id); // Include userId in the payload

    try {
      const response = await axios.post(
        "https://api-e2vtt5kn2q-uc.a.run.app/api/recipes/image",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 30000,
        }
      );
      setRecipe(response.data);
    } catch (err) {
      console.error("API Error:", err);
      setError(
        err.response?.data?.error ||
          "Failed to fetch recipe from image. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        setImageFile(file);
        setError("");
      } else {
        setError("Please upload a valid image file.");
      }
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Recipe Importer
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
          label="Recipe URL"
          variant="outlined"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white", // Default border color
              },
              "&:hover fieldset": {
                borderColor: "lightgray", // Hover border color
              },
              "&.Mui-focused fieldset": {
                borderColor: "#4caf50", // Focused border color
              },
            },
            "& .MuiInputLabel-root": {
              color: "white", // Label color
            },
          }}
        />
        <CustomButton onClick={handleImportByUrl} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : "Import Recipe by URL"}
        </CustomButton>

        <Typography variant="h6" sx={{ marginTop: 2 }}>
          Or Import from an Image:
        </Typography>
        <Button
          variant="contained"
          component="label"
          disabled={loading}
          sx={{ marginBottom: 1 }}
        >
          Upload Image
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={handleFileChange}
          />
        </Button>
        {imageFile && (
          <Typography variant="body2" sx={{ marginTop: 1 }}>
            Selected file: {imageFile.name}
          </Typography>
        )}
        <CustomButton onClick={handleImportByImage} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : "Import Recipe by Image"}
        </CustomButton>
      </Box>

      {error && (
        <Typography color="error" sx={{ marginTop: 2 }}>
          {error}
        </Typography>
      )}

      {recipe && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h5">{recipe.title}</Typography>
          {recipe.image && (
            <img
              src={recipe.image}
              alt={recipe.title}
              style={{ maxWidth: "300px", marginTop: 16 }}
            />
          )}

          <Typography variant="body2" sx={{ marginTop: 2 }}>
            {recipe.description}
          </Typography>

          <Typography variant="h6" sx={{ marginTop: 2 }}>
            Ingredients:
          </Typography>
          <ul>
            {recipe.ingredients.map((ingredient, index) => (
              <li key={index}>
                {`${ingredient.quantity || ""} ${ingredient.unit || ""} ${
                  ingredient.name
                }`}
              </li>
            ))}
          </ul>

          <Typography variant="h6" sx={{ marginTop: 2 }}>
            Instructions:
          </Typography>
          <ol>
            {recipe.instructions.map((instruction, index) => (
              <li key={index}>
                {instruction.instruction}
                {instruction.ingredients &&
                  instruction.ingredients.length > 0 && (
                    <ul>
                      {instruction.ingredients.map(
                        ({ quantity, unit, name }, iIndex) => (
                          <li key={iIndex}>{`${quantity || ""} ${
                            unit || ""
                          } ${name}`}</li>
                        )
                      )}
                    </ul>
                  )}
              </li>
            ))}
          </ol>

          {recipe.cuisine && (
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              Cuisine: {recipe.cuisine}
            </Typography>
          )}

          {recipe.categories && recipe.categories.length > 0 && (
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="h6">Categories:</Typography>
              <ul>
                {recipe.categories.map((category, index) => (
                  <li key={index}>{category}</li>
                ))}
              </ul>
            </Box>
          )}

          {recipe.tags && recipe.tags.length > 0 && (
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="h6">Tags:</Typography>
              <ul>
                {recipe.tags.map((tag, index) => (
                  <li key={index}>{tag}</li>
                ))}
              </ul>
            </Box>
          )}

          {recipe.nutrition && (
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="h6">Nutrition Information:</Typography>
              <ul>
                {Object.entries(recipe.nutrition).map(([key, value], index) => (
                  <li key={index}>
                    {key.replace("Content", "")}: {value}
                  </li>
                ))}
              </ul>
            </Box>
          )}
        </Box>
      )}
    </Container>
  );
};

export default RecipeImport;
