import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { formatTime } from "../utils/timeUtils";
import {
  Button,
  Box,
  Typography,
  CircularProgress,
  Chip,
  Avatar,
} from "@mui/material";

const FullRecipeView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [recipe, setRecipe] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchRecipe = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(
        `https://api-e2vtt5kn2q-uc.a.run.app/api/recipes/${id}`
      );
      setRecipe(response.data);
    } catch (err) {
      console.error("Failed to fetch recipe:", err);
      setError("Failed to fetch recipe. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    const scrollPosition = location.state?.scrollPosition || 0;
    navigate("/recipes", { state: { scrollPosition } });
  };

  useEffect(() => {
    fetchRecipe();
  }, [id]);

  return (
    <Box
      className="full-screen" // Apply the full-screen class
      sx={{ marginTop: 0, padding: 2 }}
    >
      {/* Breadcrumb */}
      <Button
        variant="text"
        onClick={handleGoBack}
        sx={{
          color: "#fff", // Custom text color
          textTransform: "none", // Optional: Keep the text as-is (no uppercase transformation)
          "&:hover": {
            color: "#0056b3", // Hover color
            backgroundColor: "transparent", // Ensure no background on hover
          },
        }}
      >
        &larr; Back to My Recipes
      </Button>
      <br />
      {/* Loading and Error States */}
      {loading && <CircularProgress />}
      {error && (
        <Typography color="error" sx={{ fontSize: "1.2rem", marginTop: 2 }}>
          {error}
        </Typography>
      )}

      {/* Recipe Content */}
      {recipe && (
        <Box>
          {/* Title */}
          <Typography variant="h3" gutterBottom sx={{ fontSize: "2.5rem" }}>
            {recipe.title}
          </Typography>

          {/* Featured Image */}
          {recipe.image && (
            <img
              src={recipe.image}
              alt={recipe.title}
              style={{
                maxWidth: "100%",
                maxHeight: "300px",
                objectFit: "cover",
                borderRadius: "16px",
                marginTop: "16px",
              }}
            />
          )}

          {/* Recipe Credit */}
          {recipe.author && (
            <Box sx={{ marginTop: 3, display: "flex", alignItems: "center" }}>
              {recipe.authorImage && (
                <Avatar
                  alt={recipe.author}
                  src={recipe.authorImage}
                  sx={{ marginRight: 2 }}
                />
              )}
              <Box>
                <Typography variant="subtitle1" sx={{ fontSize: "1.2rem" }}>
                  Recipe by <strong>{recipe.author}</strong>
                  {recipe.blogName && (
                    <>
                      {" "}
                      from{" "}
                      <a
                        href={recipe.sourceUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "underline",
                          color: "#4caf50",
                        }}
                      >
                        {recipe.blogName}
                      </a>
                    </>
                  )}
                </Typography>
              </Box>
            </Box>
          )}

          {/* Recipe Details */}
          <Box sx={{ marginTop: 4 }}>
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
              Details:
            </Typography>
            <Typography>
              <strong>Prep Time:</strong> {formatTime(recipe.prepTime)}
            </Typography>
            <Typography>
              <strong>Cook Time:</strong> {formatTime(recipe.cookTime)}
            </Typography>
            <Typography>
              <strong>Total Time:</strong> {formatTime(recipe.totalTime)}
            </Typography>
            <Typography>
              <strong>Servings:</strong>{" "}
              {Array.isArray(recipe.yield) && recipe.yield[1]
                ? recipe.yield[1]
                : "N/A"}
            </Typography>
            {recipe.cuisine && (
              <Typography>
                <strong>Cuisine:</strong> {recipe.cuisine}
              </Typography>
            )}
          </Box>

          {/* Categories and Tags */}
          <Box sx={{ marginTop: 4 }}>
            {recipe.categories && recipe.categories.length > 0 && (
              <>
                <Typography variant="h6">Categories:</Typography>
                <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                  {Array.isArray(recipe.categories) ? (
                    recipe.categories.map((category, index) => (
                      <Chip key={index} label={category} color="primary" />
                    ))
                  ) : recipe.categories ? (
                    <Chip label={recipe.categories} color="primary" />
                  ) : (
                    <Typography variant="body2">
                      No categories available
                    </Typography>
                  )}
                </Box>
              </>
            )}
            {recipe.tags && recipe.tags.length > 0 && (
              <>
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  Tags:
                </Typography>
                <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                  {recipe.tags.map((tag, index) => (
                    <Chip key={index} label={tag} color="secondary" />
                  ))}
                </Box>
              </>
            )}
          </Box>

          {/* Ingredients */}
          <Typography variant="h4" sx={{ marginTop: 4, fontSize: "2rem" }}>
            Ingredients:
          </Typography>
          <ul style={{ fontSize: "1.5rem", lineHeight: "2rem" }}>
            {recipe.ingredients.map((ingredient, index) => (
              <li key={index}>
                {`${ingredient.quantity || ""} ${ingredient.unit || ""} ${
                  ingredient.name
                }`}
              </li>
            ))}
          </ul>

          {/* Instructions */}
          <Typography variant="h4" sx={{ marginTop: 4, fontSize: "2rem" }}>
            Instructions:
          </Typography>
          <ol style={{ fontSize: "1.5rem", lineHeight: "2rem" }}>
            {recipe.instructions.map((step) => (
              <li key={step.stepNumber}>
                {step.instruction}
                {step.ingredients && step.ingredients.length > 0 && (
                  <Box
                    sx={{
                      marginLeft: 2,
                      marginTop: 1,
                      fontSize: "1rem",
                      color: "#757575",
                    }}
                  >
                    <strong>Ingredients used:</strong>{" "}
                    {step.ingredients
                      .map(
                        (ing) =>
                          `${ing.quantity || ""} ${ing.unit || ""} ${ing.name}`
                      )
                      .join(", ")}
                  </Box>
                )}
              </li>
            ))}
          </ol>

          {/* Nutrition Information */}
          {recipe.nutrition && (
            <Box sx={{ marginTop: 4 }}>
              <Typography variant="h6">Nutrition Information:</Typography>
              <ul>
                {Object.entries(recipe.nutrition).map(([key, value]) => (
                  <li key={key}>
                    {key.replace("Content", "")}: {value}
                  </li>
                ))}
              </ul>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default FullRecipeView;
