import React from "react";
import { useUser } from "../context/UserContext";
import "./ProfilePage.css";

const Profile = () => {
  const { user } = useUser();

  if (!user) {
    return <p>Please log in to view your profile.</p>;
  }

  return (
    <div className="profile-page">
      {/* Profile Picture Section */}
      <div className="profile-header">
        <div className="profile-picture-container">
          <img
            src={user.picture || "/placeholder-profile.png"}
            alt={user.name || "User"}
            className="profile-picture"
          />
        </div>
        <h1 className="profile-name">{user.name}</h1>
        <p className="profile-email">{user.email}</p>
      </div>

      {/* Account Details Section */}
      <div className="profile-details">
        <h2>Account Details</h2>
        <p>
          <strong>Name:</strong> {user.name}
        </p>
        <p>
          <strong>Email:</strong> {user.email}
        </p>

        {/* Placeholder for future features */}
        <button className="cta-button" disabled>
          Edit Profile (Coming Soon)
        </button>
      </div>
    </div>
  );
};

export default Profile;
