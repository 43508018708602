// src/utils/timeUtils.js

/**
 * Format ISO 8601 duration (e.g., PT15M) into a human-readable format.
 *
 * @param {string} duration - ISO 8601 duration string (e.g., PT15M, PT2H30M).
 * @return {string} - Human-readable time (e.g., "2 hrs 30 mins").
 */
export const formatTime = (duration) => {
  if (!duration) return "N/A"; // Handle empty or undefined durations

  const timeRegex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/; // Match hours, minutes, seconds
  const matches = duration.match(timeRegex);

  if (!matches) return duration; // If the format doesn't match, return as is

  const hours = matches[1]
    ? `${matches[1]} hr${matches[1] > 1 ? "s" : ""}`
    : "";
  const minutes = matches[2]
    ? `${matches[2]} min${matches[2] > 1 ? "s" : ""}`
    : "";
  const seconds = matches[3]
    ? `${matches[3]} sec${matches[3] > 1 ? "s" : ""}`
    : "";

  return [hours, minutes, seconds].filter(Boolean).join(" "); // Join non-empty parts
};
