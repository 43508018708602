// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth"; // Import Firebase Auth
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBkRAeipjTopoE05ZYP53kI8nS9I5i_hDc",
  authDomain: "tastora-9b2a7.firebaseapp.com",
  projectId: "tastora-9b2a7",
  storageBucket: "tastora-9b2a7.firebasestorage.app",
  messagingSenderId: "778406526027",
  appId: "1:778406526027:web:19e9b0ad0c87f98012e1ce",
  measurementId: "G-XR1YZJZENW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Functions
const functions = getFunctions(app);

// Initialize Firebase Authentication
const auth = getAuth(app);

const analytics = getAnalytics(app);

// Export functions for use in other files
export { auth, functions, analytics };
