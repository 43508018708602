import React from "react";
import "./CustomButton.css";

const CustomButton = ({
  children,
  onClick,
  type = "button",
  disabled,
  style,
}) => {
  return (
    <button
      className="custom-button"
      onClick={onClick}
      type={type}
      disabled={disabled}
      style={style} // Pass dynamic styles
    >
      {children}
    </button>
  );
};

export default CustomButton;
