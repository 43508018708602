import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"; // Import Navigate for redirects
import RecipeImport from "./components/RecipeImport";
import MyRecipes from "./components/MyRecipes";
import FullRecipeView from "./components/FullRecipeView";
import BottomNav from "./components/BottomNav";
import logo from "./t-logo.svg";
import "./App.css";
import LandingPage from "./components/LandingPage";
import { UserProvider, useUser } from "./context/UserContext"; // Import UserProvider and useUser
import ProfilePage from "./components/ProfilePage";

function AppContent() {
  const { user } = useUser(); // Access user from UserContext

  return (
    <div className="app-container">
      {/* Header */}
      <header className="app-header">
        <img src={logo} alt="Flaveo Logo" className="app-logo" />
        <h1 className="app-title">FLAVEO</h1>
      </header>
      {/* Main Content */}
      <main className="app-main">
        <Routes>
          {/* Redirect logged-in users from / to /recipes */}
          <Route
            path="/login"
            element={
              user ? <Navigate to="/recipes" replace /> : <LandingPage />
            }
          />
          <Route
            path="/"
            element={
              user ? <Navigate to="/recipes" replace /> : <LandingPage />
            }
          />
          <Route path="/signup" element={<div>Signup Page</div>} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/import" element={<RecipeImport />} />
          <Route path="/recipes" element={<MyRecipes />} />
          <Route path="/recipes/:id" element={<FullRecipeView />} />
        </Routes>
      </main>
      {/* Bottom Navigation */}
      {user && <BottomNav />} {/* Show BottomNav only for logged-in users */}
    </div>
  );
}

function App() {
  return (
    <UserProvider>
      <Router>
        <AppContent />
      </Router>
    </UserProvider>
  );
}

export default App;
